var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-lg my-5",staticStyle:{"height":"auto"}},[_vm._m(0),_c('b-overlay',{staticClass:"d-inline-block brand-overlay",attrs:{"show":_vm.paginatedCompanies.length === 0,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-start"},[_c('b-spinner',{attrs:{"variant":"primary","small":"","label":"Spinning"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Grabbing brands, just a sec...")])],1)]},proxy:true}])},[(!_vm.isMobile)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row pb-3 row-display",style:([
                  _vm.paginatedCompanies.length < _vm.cardsPerRow
                    ? { 'justify-content': 'start' }
                    : {} ])},_vm._l((_vm.paginatedCompanies.slice(
                    0,
                    _vm.cardsPerRow
                  )),function(companyCard,index){return _c('div',{key:index,staticClass:"col-auto cursor",on:{"click":function($event){return _vm.goToBrand(companyCard.companyId)}}},[_c('BrowseCard',{attrs:{"company":companyCard,"width":350,"height":120}})],1)}),0)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row row-display",style:([
                  _vm.paginatedCompanies.length < _vm.cardsPerRow
                    ? { 'justify-content': 'start' }
                    : {} ])},_vm._l((_vm.paginatedCompanies.slice(
                    _vm.cardsPerRow
                  )),function(companyCard1,index1){return _c('div',{key:index1,staticClass:"col-auto cursor",on:{"click":function($event){return _vm.goToBrand(companyCard1.companyId)}}},[_c('BrowseCard',{attrs:{"company":companyCard1,"width":350,"height":120}})],1)}),0)])])])])]):_c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.paginatedCompanies),function(companyCard,index){return _c('div',{key:index,staticClass:"col-auto pb-3 cursor",on:{"click":function($event){return _vm.goToBrand(companyCard.companyId)}}},[_c('BrowseCard',{attrs:{"company":companyCard,"width":350,"height":120}})],1)}),0)])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex mt-4 browse-links-position",style:([
        _vm.perPage < 6
          ? { 'justify-content': 'center' }
          : { 'justify-content': 'end' } ])},[_c('b-link',{staticClass:"browse-links me-4",class:_vm.isPrevDisabled,on:{"click":function($event){return _vm.onPageChanged('prev')}}},[_vm._v(" Previous Brands ")]),_c('b-link',{staticClass:"browse-links mr-0",class:_vm.isNextDisabled,on:{"click":function($event){return _vm.onPageChanged('next')}}},[_vm._v(" Next Brands ")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',{},[_vm._v("Browse brands making a positive impact")]),_c('h4',{},[_vm._v("A subtitle could go here")])])])])])}]

export { render, staticRenderFns }